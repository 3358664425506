import { PropsWithChildren, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export default function Guest({
  success,
  error,
  uid,
  children,
}: PropsWithChildren<{ success?: string; error?: string; uid?: string }>) {
  useEffect(() => {
    {
      error && toast.error(error);
    }
    {
      success && toast.success(success);
    }
  }, [uid, error, success]);

  return (
    <>
      <main className="form-screens">{children}</main>

      <ToastContainer theme="colored" autoClose={5000} />
    </>
  );
}
